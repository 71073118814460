const ProjectData = [
    {
        "id": 1,
        "imageUrl": "",
        "sourceUrl": "https://jackking.site",
        "name": "Mr. Jack King | Professional Profile & E-Portfolio",
        "description": "The professional profile for myself, Jack King. Acting as a landing page for my porfessional profile and e-portfolio. Made with vanilla HTML and CSS.",
        "displayOnHome": true
    },
    {
        "id": 2,
        "imageUrl": "https://nceptior.sirv.com/compatibilityfriend/compatibility-friend-logo.png",
        "sourceUrl": "",
        "name": "Compatibility Friend | Motherboard Compatibility Tool",
        "description": "Coming soon!",
        "displayOnHome": false
    },
    {
        "id": 3,
        "imageUrl": "https://nceptior.sirv.com/bexbackyard.com.au/logo192.png",
        "sourceUrl": "https://therealjackk.github.io/bex-backyard/",
        "name": "Bex Backyard | Terrariums Made With Love",
        "description": "An e-commerce website built in ReactJS for a Sydney based small business that specialises in Terrariums, Plants and souvenire nick nacks.",
        "displayOnHome": true
    },
    {
        "id": 4,
        "imageUrl": "https://nceptior.sirv.com/beybladeapp/bbmf-logo.png",
        "sourceUrl": "https://therealjackk.github.io/beyblade-react-app/",
        "name": "Beyblade Metal Series | Reference and Guide",
        "description": "A compainion app made in ReactJS for collecters of 'Beyblade: Metal Fight' a popular Japanese hobby that has a presence for collectors in the west.",
        "displayOnHome": true
    },
    {
        "id": 5,
        "imageUrl": "",
        "sourceUrl": "https://houseofezz.space",
        "name": "Html Colours | Web developer colour guide",
        "description": "A simple colour app for web developers made with vanilla javascript",
        "displayOnHome": true
    }
]

export default ProjectData