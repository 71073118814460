import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <>
        <header className="header">
            <div>
                <h1>Casa de EZZ</h1>
                <h2 id="email">@</h2>
            </div>
            <span>
                <Link className="nav" to="/"><p>Home</p></Link>
                <Link className="nav" to="/Webdevelopment"><p>Web Development</p></Link>
                {/* <Link className="nav" to="/Computerassembly"><p>Computer Assesmbly</p></Link> */}
                <Link className="nav" to="/Contact"><p>Contact</p></Link>
            </span>
            <div id="hamburger-container">
                <img id="hamburger-icon" src="https://nceptior.sirv.com/casadeezz.com/hamburger.svg" alt="hamburger" />
                <ul id="hamburger-links">
                    <li><Link to="/"><p>Home</p></Link></li>
                    <li><Link to="/Webdevelopment"><p>Web Development</p></Link></li>
                    <li><Link to="/Computerassembly"><p>Computer Assesmbly</p></Link></li>
                    <li><Link to="/Contact"><p>Contact</p></Link></li>
                </ul>
            </div>
        </header>
        </>
    )
}