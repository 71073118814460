import React from "react";
import { Link } from "react-router-dom";
import ProjectData from "../data/webprojects";

export default function Webdevelopment() {
    return (
        <>
        <span class="page">
            <h2>Website Development And Maintence Services</h2>
            <h3>See Our Latest Projects</h3>
            <span>
                <span class="card-group">
                    {ProjectData.map(projects => (
                    <Link to={{pathname: projects.sourceUrl}} target="_blank">
                        <span className="card" key={projects.id}>
                            <iframe src={projects.sourceUrl} title={projects.sourceUrl}></iframe>
                            <img src={projects.imageUrl} alt={projects.name} /> 
                            <h3>{projects.name}</h3>
                            <p>{projects.description}</p>
                            <p>Click me!</p>
                        </span>
                    </Link>
                    ))}
                </span>
            </span>
        </span>
        </>
    )
}