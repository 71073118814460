const CustomPcs = [
    {
        "id": 1,
        "imageUrl1": "https://picsum.photos/500",
        "imageUrl2": "https://picsum.photos/500", 
        "imageUrl3": "https://picsum.photos/500",
        "alt1": "alt",
        "alt2": "alt",
        "alt3": "alt",     
    },
    {
        "id": 2,
        "imageUrl1": "https://picsum.photos/500",
        "imageUrl2": "https://picsum.photos/500", 
        "imageUrl3": "https://picsum.photos/500",
        "alt1": "alt",
        "alt2": "alt",
        "alt3": "alt",     
    }
]

export default CustomPcs