import React from "react";
import { Link } from "react-router-dom";
import Contactform from "./Contactform";
import ProjectData from "../data/webprojects";
import UsedTechnologies from "../data/technologies";

export default function Home() {
    return (
        <>
        <span className="page">
        <h2>Websites Made Easy</h2>
            <span>
                <img id="CDElogo" src="https://nceptior.sirv.com/casadeezzlogos/cde-logo-svg.svg" alt="CDE Logo" />
                <Contactform />
            </span>
            <h3>What is CASA DE EZZ?</h3>
            <span>
                <p>
                The name "Casa de EZZ" comes from the spanish words "House of" and the common online gaming slang "ez" or "ezz" which means 
                "easy" or "easy done", we believe this accurately represents our business model of "Websites Made Easy". Using modern technologies with the latest W3C (World Wide Web Consortium) standards, our websites are modern, fast, responsive and cost effective.
                </p>
                <p>
                
                </p>
            </span>
            <h3>See Our Latest Projects</h3>
            <span>
                <span className="card-group">
                    {ProjectData.filter(projects => projects.displayOnHome === true).map(projects => (
                        <Link to={{pathname: projects.sourceUrl}} target="_blank">
                            <span className="card" key={projects.id}>
                                <iframe src={projects.sourceUrl} title={projects.sourceUrl}></iframe>
                                <img src={projects.imageUrl} alt={projects.name} /> 
                                <h3>{projects.name}</h3>
                                <p>{projects.description}</p>
                                <p>Click me!</p>
                            </span>
                        </Link>
                    ))}
                </span>
            </span>
            <h3>Technologies We Use</h3>
            <span>
                {UsedTechnologies.map(technologies => (
                    <img id={technologies.styleId} src={technologies.imageUrl} alt={technologies.name} key={technologies.id} />
                ))}
            </span>
            <h3>Want to see more?</h3>
            <p>Check Out our Developers Githubs</p>
            <span>
                <Link to={{pathname: "https://github.com/TheRealJackK"}} target="_blank"><p>https://github.com/TheRealJackK</p></Link>
            </span>
        </span>
        </>
    )
}