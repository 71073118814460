import React from "react";

export default function Contactform() {
    return (
        <>
        <form action="https://formsubmit.co/jack@casa-de-ezz.com" method="POST">
            <div>
                <h3>Send us an Email!</h3>
                <label>Email</label>
                <input type="email" name="email" id="email" placeholder="myemail@domain.com" required></input>
                <label>Subject</label>
                <input type="text" name="_honey" id="honeypot"></input>
                <select name="subject" id="subject" required>
                    <option>Website Development</option>
                    <option>Computer Assembly And Repair</option>
                </select>
                <label>Body</label>
                <textarea rows="5" cols="60" name="message" id="message" placeholder="Message Here" required></textarea>
                <input type="hidden" name="_next" value="http://192.168.68.104:3000/Thankyou"></input>
                <input type="submit" id="submit" value="Send Enquiry"></input>
            </div>
        </form>
        </>
    )
}