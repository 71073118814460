import React from 'react';
import './css/styles.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Webdevelopment from './components/Webdevelopment';
import Computerassembly from './components/Computerassembly';
import Contact from './components/Contact';
import Thankyou from './components/Thankyou';

export default function App() {
  return (
    <>
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/Webdevelopment">
          <Webdevelopment />
        </Route>
        <Route exact path="/Computerassembly">
          <Computerassembly />
        </Route>
        <Route exact path="/Contact">
          <Contact />
        </Route>
        <Route exact path="/Thankyou">
          <Thankyou />
        </Route>
      </Switch>
      <Footer />
    </Router>
    </>
  );
}
