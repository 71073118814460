import React from "react";
import Contactform from "./Contactform";

export default function Contact() {
    return(
        <>
        <span class="page">
            <h2>Contact Us</h2>
            <span>
                <Contactform />
            </span>
            <h3>Need To Contact Us?</h3>
            <span>
                <p>
                Need to enquire about our services? Request a free quote? or maybe just express your interest in our service.
                Fret not! Just send our friendly team an email with any question you may need to ask about either our website development services
                or our PC assembly and repair services and we will get back to you ASAP!
                </p>
            </span>
        </span> 
        </>
    )
}