const UsedTechnologies = [
    {
        "id": 1,
        "name": "HTML5",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/html.png",
        "styleId": "technologies"
    },
    {
        "id": 2,
        "name": "CSS3",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/css.png",
        "styleId": "technologies"
    },
    {
        "id": 3,
        "name": "Javascript",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/javascript-logo.png",
        "styleId": "technologies"
    },
    {
        "id": 4,
        "name": "ReactJS",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/React-icon.png",
        "styleId": "technologies"
    },
    {
        "id": 5,
        "name": "NextJS",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/nextjs.png",
        "styleId": "technologies"
    },
    {
        "id": 6,
        "name": "Bootstrap",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/Bootstrap_logo.png",
        "styleId": "technologies"
    },
    {
        "id": 7,
        "name": "Git",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/git.png",
        "styleId": "technologies"
    },
    {
        "id": 8,
        "name": "Github",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/github.png",
        "styleId": "technologies"
    },
    {
        "id": 9,
        "name": "Wordpress",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/wordpress.png",
        "styleId": "technologies"
    },
    {
        "id": 10,
        "name": "Formsubmit",
        "imageUrl": "https://nceptior.sirv.com/casadeezz.com/formsubmit.png",
        "styleId": "technologies"
    }
]

export default UsedTechnologies