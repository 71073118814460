import React from "react";
import CustomPcs from "../data/custompcs";
import { Link } from "react-router-dom";

export default function Computerassembly() {
    return (
        <>
        <span className="page">
            <h2>Computer Assesmbly And Repair Services</h2>
            <h3>We Build Custom Computers!</h3>
            <span>
                <span className="card-group">
                    {CustomPcs.map(pc => (
                        <Link to="">
                        <span className="card-alt" key={pc.id}>
                            <img src={pc.imageUrl1} alt={pc.alt1} />
                            <p>{pc.alt1}</p>
                            <p>Click Me!</p>
                        </span>
                        </Link>
                    ))}
                </span>
            </span>
        </span>
        </>
    )
}