import React from "react"
import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <>
        <footer className="footer">
            <span>
                <h2 id="email">@</h2>
                <h2>casa-de-ezz.com</h2>
            </span>
            <div></div>
            <span>
                <Link to="/"><p>Home</p></Link>
                <Link to="/Webdevelopment"><p>Web Development</p></Link>
                {/* <Link to="/Computerassembly"><p>Computer Assesmbly</p></Link> */}
                <Link to="/Contact"><p>Contact</p></Link>
            </span>
            <div></div>
            <span>
                <h2>Developed by Casa de EZZ</h2>
                <img src="https://nceptior.sirv.com/casadeezz.com/cde-logo-svg.svg" alt="https://www.casa-de-ezz.com"/>
            </span>
        </footer>
        </>
    )
}
