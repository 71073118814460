import React from "react";
import { Link } from "react-router-dom";

export default function Thankyou() {
    return (
        <>
        <span className="page">
            <h2>Thank You!</h2>
            <span>
                <p>
                Thank you for sending us an email! We appreciate your time and paitence, sit tight and one of our friendly team members 
                will get back to you ASAP! In the meantime feel free to look at or visit some of our previous projects.
                </p>
            </span>
            <span>
                <Link to="/Webdevelopment"><h3>Website Development</h3></Link>
                <Link to="/Computerassembly"><h3>Computer Assesmbly</h3></Link>
            </span>
            <h3>Want to see more?</h3>
            <p>Check Out our Developers Githubs</p>
            <span>
                <Link to={{pathname: "https://github.com/TheRealJackK"}} target="_blank"><p>https://github.com/TheRealJackK</p></Link>
            </span>
        </span>
        </>
    )
}